/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0 !important; // resets padding right added by Bootstrap modal
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.container-scroller {
  overflow: hidden;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: theme-color(danger);
  font-family: $type1;
  font-weight: 300;
  font-size: $default-font-size;
  border-radius: 4px;
}
.donut-legend{
  @include display-flex;
  @include justify-content(center);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  >span{
    @include display-flex;
    @include align-items(center);
    margin-right: 15px;
    margin-top: 20px;
    font-size: $default-font-size;
    >span{
      display: block;
      width: 10px;
      height: 10px;
      margin-right: 10px;
      @include border-radius(50px);
    }
  }
  .rtl & {
    span{
      margin-right: 0;
      margin-left: 10px;
    }
  }
}
