/* Tabs */

// Basic Styles
.nav{
	margin-bottom: 1rem;
	&.nav-tabs,&.nav-pills{
		.nav-item{
			.nav-link{
				font-family: $type1;
				line-height: 1;
				padding: 13px 28px;
				@media (max-width: 767px) {
					padding: 13px 10px;
				}
				font-size: $default-font-size;
				color: $black;
				@include display-flex;
				@include border-radius(2px);
				i{
					margin-right: 10px;
				}
			}
		}
	}
}
.tab-content{
	padding: 37px 18px 29px 24px;
	font-family: $type1;
	font-size: $default-font-size;
	line-height: 1.71;
	border: $border-width solid $border-color;
	@include border-radius(2px);

}

// Basic Tab Styles
.tab-basic{
	border-bottom: none;
	.nav-item{
		.nav-link{
			&.active{
				border: $border-width solid $border-color;
			}
		}
	}
}
.tab-content-basic{
	border: $border-width solid $border-color;
}

// Solid Tab Styles
.tab-solid{
	border: none;
	.nav-item{
		.nav-link{
			border: none;
			&.active{
				border: none;
				color: $white;
			}
		}
	}
}
.tab-content-solid{
	border: none;
	padding-top: 0.875rem;
	padding-left: 0;
	padding-right: 0;
}

@each $color, $value in $theme-colors {
  .tab-solid-#{$color} {
    @include tab-solid-variant($value);
  }
}

// Minimal Tab Styles
.tab-minimal{
	.nav-tabs{
		border: none;
		.nav-item{
			.nav-link{
				border: none;
				&.active{}
				&:first-child{
					padding-left: 0;
				}
			}
		}
	}
	.tab-content{}
}

@each $color, $value in $theme-colors {
  .tab-minimal-#{$color} {
    @include tab-minimal-variant($value);
  }
}

// Vertical Tab Styles
.vertical-tab{
	@include display-flex;
	.nav-tabs{
		margin-right: 1.25rem;
		@include flex-direction(column);
	}
	.tab-content{
		margin-bottom: 0px;
	}
}