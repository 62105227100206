@each $color, $value in $social-colors {
  .bg-#{$color} {
    background: social-color($color);
  }
}

@each $color, $value in $theme-gradient-colors {
  .bg-gradient-#{$color} {
    background: $value;
  }
}
@mixin tab-solid-variant($color) {
  .nav-link {
    &.active {
      background: $color;
    }
  }
}
@mixin tab-minimal-variant($color) {
  .nav-tabs {
    .nav-item {
      .nav-link {
        &.active {
          color: $color;
        }
      }
    }
  }

  .tab-content {
    border-color: $color;
  }
}
// Badge variations
@mixin badge-variations($color) {
  border: 1px solid $color;
  color: $white;
}
// Badge outlined variations
@mixin badge-outline-variations($color) {
  color: $color;
  border: 1px solid $color;
}
// Pagination variations
@mixin pagination-variants($color) {
  .page-item {
    &.active {
      .page-link {
        background: $color;
        border-color: $color;
      }
    }

    .page-link {
      &:hover {
        background: lighten($color,5%);
        border-color: $color;
        color: $white;
      }
    }
  }
}
// Accordion Inverse variations
@mixin accordion-inverse-variant($color) {
  background: rgba($color, 0.16);
  color: $color;
  border: none;

  .card-header {
    color: $color;
    border: none;

    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      color: $color;
    }

    a {
      border-color: $color;

      &:before {
        color: $color;
      }

      &[aria-expanded="true"] {
        border-bottom: 1px solid rgba($color, 0.16);
      }

      &[aria-expanded="false"] {
        border-bottom: 1px solid transparent;
      }
    }
  }
}