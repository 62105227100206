////////// SIDEBAR ////////
$sidebar-width-lg: 255px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

$sidebar-light-bg: $white;
$sidebar-light-menu-color: #8d9498;
$sidebar-light-submenu-color: #656565;
$sidebar-light-menu-active-bg: initial;
$sidebar-light-menu-active-border: #5f6be3;
$sidebar-light-menu-active-color: #404852;
$sidebar-light-menu-hover-bg: #f6f6f6;
$sidebar-light-menu-hover-color: #8d9498;
$sidebar-light-submenu-hover-bg: initial;
$sidebar-light-submenu-hover-color: #000;

$sidebar-light-submenu-hover-color: #000;
$sidebar-light-category-color: #999999;
$sidebar-light-menu-icon-color: #404852;
$sidebar-light-profile-name-color: #404852;
$sidebar-light-profile-title-color: #8d9498;

$sidebar-light-submenu-active-color: $black;
$sidebar-light-menu-icon-color: #000000;
$sidebar-light-menu-arrow-color: #404852;
$sidebar-light-profile-name-color: #404852;
$sidebar-light-profile-title-color: #8d9498;

$sidebar-dark-bg: #3a3f51;
$sidebar-dark-menu-color: #fff;
$sidebar-dark-menu-active-bg: initial;
$sidebar-dark-menu-active-color: #c0bbbb; 
$sidebar-dark-menu-hover-bg: #39363f;
$sidebar-dark-menu-hover-color: color(white);;
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: #9a94a7;
$sidebar-dark-menu-icon-color: $sidebar-dark-menu-color;
$sidebar-dark-profile-name-color: #ffffff;
$sidebar-dark-profile-title-color: #8d9498;

$sidebar-category-font-size: .6875rem;
$sidebar-category-padding: .75rem 1.188rem;

$sidebar-menu-font-size: .875rem;
$sidebar-menu-padding-left: 1.25rem;
$sidebar-menu-padding-right: 1.25rem;
$sidebar-menu-padding-top: .75rem;
$sidebar-menu-padding-bottom: .75rem;

$nav-link-height: 52px;

$sidebar-menu-padding: 1rem 1.875rem;

$sidebar-submenu-padding:  0 0 0 4.25rem;
$sidebar-submenu-font-size: .875rem;
$sidebar-submenu-item-padding:.75rem 1rem;

$sidebar-icon-font-size: .9375rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0 1.625rem 2.25rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$sidebar-box-shadow: none;

$rtl-sidebar-submenu-padding: 0 4.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: $sidebar-light-bg;
$icon-only-menu-bg-dark: $sidebar-dark-bg;

///////// SIDEBAR ////////

///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

///////// SETTINGS PANEL ////////

$settings-panel-width: 300px;

///////// NAVBAR ////////

$navbar-default-bg: $white;
$navbar-height: 58px;
$navbar-menu-color: #9b9b9b;
$navbar-light-color: #202339;
$navbar-font-size: .9375rem;
$navbar-icon-font-size: 1.25rem;

///////// NAVBAR ////////